export default class ProjectListTableItem {
    projectId: number = null;
    projectStatus: number = null;
    orgCode: number = null;
    projectYeahId: number = null;
    projectTypeId: number = null;
    projectCraftsId: number = null;
    projectOptModeId: number = null;
    projectLayerId: number = null;
    projectProductionId: number = null;
    projectImplementId: number = null;
    projectInformationId: number = null;
    projectIntegrationId: number = null;
    cockpitCode = "";
    assetCode = "";
    projectCode = "";
    projectName = "";
    projectRemark = "";

    orgCodeName = "";
    projectYeahIdName = "";
    projectTypeIdName = "";
    projectCraftsIdName = "";
    projectOptModeIdName = "";
    projectLayerIdName = "";
    projectMachineIdName = "";
    projectInformationIdName = "";
    projectIntegrationIdName = "";

    createTime = "";
    createName = "";
    modifyTime = "";
    modifyName = "";
}
