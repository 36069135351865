import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import ProjectListTableItem from "@/entity/project/ProjectListTableItem";
import API from "@/api/project/project-list";
import OrgAPI from "@/api/system/org";
import ProjectTypeAPI from "@/api/project/project-type";
import ProjectCraftsAPI from "@/api/project/project-crafts";
import DictionaryAPI from "@/api/system/dictionary";

@Options({
    name: "app-project-project-list",
    components: {
        "app-select-level": SelectLevel,
    },
})
export default class ProjectList extends BaseTableMixins<ProjectListTableItem> implements Partial<BaseTableInterface<ProjectListTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new ProjectListTableItem();
        this.editModalItem = new ProjectListTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            orgCode: {
                type: "router",
                name: "org_code",
                title: "所属组织",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.orgCodeLevelModalOpen,
            },
            projectYeahId: {
                type: "eq",
                code: "prjYeah",
                name: "project_yeah_id",
                title: "设备年份",
                parent: 40,
                value: null,
                openFn: this.dictionaryModalOpen,
            },
            projectOptModeId: {
                type: "eq",
                code: "prjOptMode",
                name: "project_opt_mode_id",
                title: "运营模式",
                parent: 18,
                value: null,
                openFn: this.dictionaryModalOpen,
            },
            projectLayerId: {
                type: "eq",
                code: "prjLayerId",
                name: "project_layer_id",
                title: "设备层数",
                parent: 85,
                value: null,
                openFn: this.dictionaryModalOpen,
            },
            projectTypeId: {
                type: "eq",
                name: "project_type_id",
                title: "设备类型",
                parent: -1,
                lockPid: -1,
                value: null,
                openFn: this.projectTypeIdLevelModalOpen,
            },
            projectCraftsId: {
                type: "eq",
                name: "project_crafts_id",
                title: "设备工艺",
                parent: -1,
                lockPid: null,
                value: null,
                openFn: this.projectCraftsIdLevelModalOpen,
            },
            projectMachineId: {
                type: "eq",
                code: "prjMachine",
                name: "project_machine_id",
                title: "机械",
                parent: 70,
                value: null,
                openFn: this.dictionaryModalOpen,
            },
            projectInformationId: {
                type: "eq",
                code: "prjInformation",
                name: "project_information_id",
                title: "信息",
                parent: 71,
                value: null,
                openFn: this.dictionaryModalOpen,
            },
            projectIntegrationId: {
                type: "eq",
                code: "prjIntegration",
                name: "project_integration_id",
                title: "集成",
                parent: 72,
                value: null,
                openFn: this.dictionaryModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //OrgAPI
    get getOrgApi(): any {
        return OrgAPI;
    }

    //ProjectTypeAPI
    get getProjectTypeApi(): any {
        return ProjectTypeAPI;
    }

    //ProjectCraftsAPI
    get getProjectCraftsApi(): any {
        return ProjectCraftsAPI;
    }

    //DictionaryAPI
    get getDictionaryAPI(): any {
        return DictionaryAPI;
    }

    //后端API
    public getApi(): any {
        return API;
    }

    // //列表回调
    // public listCallback(items: Array<ProjectTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new ProjectListTableItem();
        this.editModalItem = new ProjectListTableItem();
        this.editModalItem.projectStatus = 1;
        this.editModalTitle = "添加设备";
        this.setProjectCraftsParentId(null);
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.projectYeahId)) return "设备年份不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.projectName)) return "设备名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.projectStatus)) return "设备状态不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.projectTypeId)) return "设备类型不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.orgCode)) return "所属组织不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.projectOptModeId)) return "操作模式不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.projectLayerId)) return "设备层数不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改设备 (" + this.editModalItem.projectName + ")";
        this.setProjectCraftsParentId(null);
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.projectId)) return "设备ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除设备",
            message: "设备名称: " + this.editModalItem.projectName + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.projectId)) return "设备ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "设备状态",
            message: "设备名称: " + this.editModalItem.projectName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.projectStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.projectStatus = this.editModalItem.projectStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.projectId)) return "设备ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //orgCode搜索字段
    private orgCodeLevelSearchField: any = [
        { field: "org_code", name: "代码" },
        { field: "org_name", name: "名称" },
        { field: "org_remark", name: "说明" },
    ];
    //orgCode层级选择器打开
    public orgCodeLevelModalOpen() {
        (this.$refs.orgCodeLevel as any).openModal();
    }
    //orgCode层级选择器提交
    public async orgCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgCode : item;
        this.tableRequestItem.lockItem.orgCode.value = curItem.value;
        this.tableRequestItem.lockItem.orgCode.title = curItem.title;
        this.tableRequestItem.lockItem.orgCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //dictionary搜索字段
    private currentLockCode = "projectYeahId";
    private dictionarySearchField: any = [
        { field: "dictionary_code", name: "代码" },
        { field: "dictionary_name", name: "名称" },
        { field: "dictionary_remark", name: "说明" },
    ];
    //dictionary层级选择器打开
    public dictionaryModalOpen(lockCode: string) {
        this.currentLockCode = lockCode;
        this.$nextTick(() => {
            (this.$refs.dictionaryLevel as any).openModal();
        }).then();
    }
    //dictionary层级选择器提交
    public async dictionaryFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem()[this.currentLockCode] : item;
        this.tableRequestItem.lockItem[this.currentLockCode].value = curItem.value;
        this.tableRequestItem.lockItem[this.currentLockCode].title = curItem.title;
        this.tableRequestItem.lockItem[this.currentLockCode].parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //projectTypeId搜索字段
    private projectTypeIdLevelSearchField: any = [
        { field: "project-type_name", name: "名称" },
        { field: "project-type_remark", name: "说明" },
    ];
    //projectTypeId层级选择器打开
    public projectTypeIdLevelModalOpen() {
        (this.$refs.projectTypeIdLevel as any).openModal();
    }
    //projectTypeId层级选择器提交
    public async projectTypeIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().projectTypeId : item;
        this.tableRequestItem.lockItem.projectTypeId.value = curItem.value;
        this.tableRequestItem.lockItem.projectTypeId.title = curItem.title;
        this.tableRequestItem.lockItem.projectTypeId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //projectCraftsId搜索字段
    private projectCraftsIdLevelSearchField: any = [
        { field: "project-type_name", name: "名称" },
        { field: "project-type_remark", name: "说明" },
    ];
    //projectCraftsId层级选择器打开
    public projectCraftsIdLevelModalOpen() {
        (this.$refs.projectCraftsIdLevel as any).openModal();
    }
    //projectCraftsId层级选择器提交
    public async projectCraftsIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().projectCraftsId : item;
        this.tableRequestItem.lockItem.projectCraftsId.value = curItem.value;
        this.tableRequestItem.lockItem.projectCraftsId.title = curItem.title;
        this.tableRequestItem.lockItem.projectCraftsId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get orgCodeSelectApi() {
        return {
            api: (query: any) => OrgAPI.idSelect(query),
            parent: "org_parent_code",
            status: "org_status",
            name: "org_name",
            id: "org_code",
            extend: "false",
            cid: this.editModalItem.orgCode,
        };
    }

    get projectTypeIdSelectApi() {
        return {
            api: (query: any) => ProjectTypeAPI.idSelect(query),
            parent: "project_type_parent_id",
            status: "project_type_status",
            name: "project_type_name",
            id: "project_type_id",
            extend: "false",
            cid: this.editModalItem.projectTypeId,
            lockPid: this.tableRequestItem.lockItem.projectTypeId.lockPid,
        };
    }

    get projectCraftsIdSelectApi() {
        return {
            api: (query: any) => ProjectTypeAPI.idSelect(query),
            parent: "project_type_parent_id",
            status: "project_type_status",
            name: "project_type_name",
            id: "project_type_id",
            extend: "false",
            cid: this.editModalItem.projectCraftsId,
            lockPid: this.tableRequestItem.lockItem.projectCraftsId.lockPid,
        };
    }

    private setProjectName() {
        let projectName = "";
        projectName += this.editModalItem.projectYeahIdName;
        projectName += this.editModalItem.orgCodeName;
        projectName += this.editModalItem.projectOptModeIdName;
        projectName += this.editModalItem.projectCraftsIdName;
        if (this.editModalItem.projectLayerIdName != "单层") projectName += this.editModalItem.projectLayerIdName;
        projectName += this.editModalItem.projectTypeIdName;

        if (!this.$tools.isEmpty(this.editModalItem.cockpitCode)) {
            const cockpitCode = this.editModalItem.cockpitCode.split("-");
            if (cockpitCode.length === 3) projectName += `${cockpitCode[0]}-${cockpitCode[2]}`;
        }
        this.editModalItem.projectName = projectName;
    }

    private setProjectYeahIdName(name: any) {
        this.editModalItem.projectYeahIdName = name;
        this.setProjectName();
    }

    private setOrgCodeName(name: any) {
        this.editModalItem.orgCodeName = name;
        this.setProjectName();
    }

    private setProjectOptModeIdName(name: any) {
        this.editModalItem.projectOptModeIdName = name;
        this.setProjectName();
    }

    private setProjectLayerIdName(name: any) {
        this.editModalItem.projectLayerIdName = name;
        this.setProjectName();
    }

    private setProjectCraftsIdName(name: any) {
        this.editModalItem.projectCraftsIdName = name;
        this.setProjectName();
    }

    private setProjectCraftsParentId(value: any) {
        if (value == null) {
            //打开编辑窗口时
            this.tableRequestItem.lockItem.projectCraftsId.lockPid = this.editModalItem.projectTypeId;
        } else {
            //编辑窗口修改值时
            this.tableRequestItem.lockItem.projectCraftsId.lockPid = value;
            this.editModalItem.projectCraftsId = null;
            this.editModalItem.projectCraftsIdName = null;
            this.$nextTick(() => {
                if (this.$refs.projectCraftsIdSelect) {
                    (this.$refs.projectCraftsIdSelect as any).clear();
                    (this.$refs.projectCraftsIdSelect as any).refreshOptions();
                }
            }).then();
        }
    }

    private setProjectTypeIdName(name: any) {
        this.editModalItem.projectTypeIdName = name;
        this.setProjectName();
    }

    private setCockpitCode() {
        this.setProjectName();
    }
}
