import requests from "@/utils/axios";

export default {
    // 模块URL
    modulesUrl: "/project/project/crafts",
    // 层级选择器
    levelSelect(query: any) {
        return requests.post(this.modulesUrl + "/levelSelect", query);
    },
};
